import JSEncrypt from "jsencrypt"

//对称加密
const publicKeyStr='MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjTvph3Qn6qIwSlCQs1Lw' +
          's4Q6qiBXwJojomfDajSkRfSm8sFnBdxhFf40IheZH4iyHRIZeC4d3f2pYH3uKJQU' +
          'Q8J/FwYN3DOKD38yqphcLTblpQFQiC5saDRZL/As6VxZF38DymjXyrH7VEUqSdAd' +
          'EH2VBS7me+fcYtadDnh0v+/tkb3OgmDCHC8YGELq4hL2NDOxoI3sA97/RLB5q5Us' +
          'ySxFoSxKE7bTRYJHio599QhMc1b6V91oJy/Fjc0poJ6Wko2HGD9AMY2FrYFtSyKJ' +
          'DLJ/22RVrCeVmuEVGkeW6q2iKVIet3H2e8DFFcaJQRhlM4m3HUVcoYWloQSrx+4N' +
          'kwIDAQAB'
const jsRsa = new JSEncrypt();
jsRsa.setPublicKey(publicKeyStr);

export const rsa = {
  encrypt:(str)=>jsRsa.encrypt(str),
  decrypt:(str)=>jsRsa.decrypt(str)
}
