import http from 'uview-ui/libs/request'
import qs from 'qs'
import store from '@/store'

http.setConfig({
	baseUrl: process.env.VUE_APP_API_BASE_URL,
  // baseUrl:'http://192.168.3.199:88',
  // 设置为json，返回后会对数据进行一次JSON.parse()
  	dataType: 'json',
	// 如果将此值设置为true，拦截回调中将会返回服务端返回的所有数据response，而不是response.data
	// 设置为true后，就需要在this.$u.http.interceptor.response进行多一次的判断，请打印查看具体值
	originalData: true,// 是否在拦截器中返回服务端的原始数据
  showLoading: true, // 是否显示请求中的loading
  loadingText: '请求中...', // 请求loading中的文字提示
  loadingTime: 1500, // 在此时间内，请求还没回来的话，就显示加载中动画，单位ms
  loadingMask: true, // 展示loading的时候，是否给一个透明的蒙层，防止触摸穿透
	// 设置自定义头部content-type
	header: {}
});
// 请求拦截，配置Token等参数
http.interceptor.request = (config) => {
  console.log('store.getters.token',store.getters.token)
  const token = store.getters.token
  if (token) {
    config.header['Authorization'] =  token
  }

  if(config.method == 'GET'){
    //不缓存数据
    config.data = config.data || {}
    config.data.timestamp = Date.parse(new Date()) / 1000
  }
  filterNull(config.data);
	return config;
}

// // 响应拦截，判断状态码是否通过
http.interceptor.response = (res) => {
	// 如果把originalData设置为了true，这里得到将会是服务器返回的所有的原始数据
	// 判断可能变成了res.statueCode，或者res.data.code之类的，请打印查看结果
  const data = res.data
  if(data.code===0){
    return typeof data.data=='boolean' ? (data.data?1:0):data.data
  }
  if(data.code && data.code!==0){
    if(data.code ==4001){
      uni.$emit('user:invalid_token',data)
    }
    return Promise.reject({...data,message:data.msg})
  }
  return data
}
function filterNull(data){
  if (data) {
    for (let k in data) {
      if (data[k] == undefined) {
        delete data[k]
      }
    }
  }
}

export const convertFormData= (params)=>{
  // let data = new FormData();
  // for(let name in params){
  //   data.append(name, params[name]);
  // }
  // return data
  return qs.stringify(params)
}

export default {
  get(url,{params,headers}={}){
    return http.get(url,params,headers)
  },
  post:http.post,
  put:http.put,
  delete(url,{data},headers){
    return http.delete(url,data,headers)
  },
  postForm(url,data,headers){
    return http.post(url,convertFormData(data),{'Content-Type': 'application/x-www-form-urlencoded',...headers})
  },
  putForm(url,data,headers){
    return http.put(url,convertFormData(data),{
    'Content-Type': 'application/x-www-form-urlencoded'
    ,...headers})
  }
}
