//这里仅示范npm安装方式的引入，其它方式引入请看最上面【安装】部分
import {RouterMount,createRouter} from 'uni-simple-router';

//初始化
const router = createRouter({
  platform: process.env.VUE_APP_PLATFORM,
  encodeURI:false,
  routes: ROUTES //uni-read-pages插件写入
});
console.log(ROUTES)
export {
	router,
	RouterMount
}