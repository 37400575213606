import { wx_login,login } from '@/api'
import { rsa } from '@/utils/encryptutil'

export default {
  state: {
    accessToken :''
  },
  mutations: {
  },
  actions: {
    login({ state, dispatch }, params) {
      return wx_login(params).then((res) => {
        state.accessToken = res.accessToken
      })
    },
    async LoginSuccess({ commit, dispatch }, res) {
      console.log('LoginSuccess',res)
      const token = res.tokenHead + ' ' +res.token
      return dispatch('setToken',res.tokenHead + ' ' +res.token)
    },
    async LoginByUsername({ dispatch }, params={}) {
      params.username = rsa.encrypt(params.username.trim())
      params.password = rsa.encrypt(params.password.trim())
      const res  = await login(params)
      return dispatch("LoginSuccess",res)
    },
  }
}
