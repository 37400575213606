
import Vue from 'vue'

global['________'] = true;
delete global['________'];
global.__uniConfig = {"globalStyle":{"navigationBarTitleText":" ","navigationBarTextStyle":"white","navigationBarBackgroundColor":"#4E8FF6","backgroundColor":"#F7F7F7","titleNView":false},"easycom":{"autoscan":true,"custom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"}},"condition":{"current":1,"list":[]}};
global.__uniConfig.compilerVersion = '3.1.22';
global.__uniConfig.router = {"mode":"history","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = "XVXBZ-NDMC4-JOGUS-XGIEE-QVHDZ-AMFV2";
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-about-about', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/about/about.vue')), 'pages-about-about'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-invite-invite', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/invite/invite.vue')), 'pages-invite-invite'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-success-success', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/success/success.vue')), 'pages-success-success'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/login/login.vue')), 'pages-login-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-qr_login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/login/qr_login.vue')), 'pages-login-qr_login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-bind', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/login/bind.vue')), 'pages-login-bind'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-user_bind', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/login/user_bind.vue')), 'pages-login-user_bind'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-login_password', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/login/login_password.vue')), 'pages-login-login_password'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-success-qr_login_success', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/success/qr_login_success.vue')), 'pages-success-qr_login_success'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-eslogin-bind', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/eslogin/bind.vue')), 'pages-eslogin-bind'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-success-bind_success', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/success/bind_success.vue')), 'pages-success-bind_success'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-success-pm_success', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/success/pm_success.vue')), 'pages-success-pm_success'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-eslogin-qr_login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/eslogin/qr_login.vue')), 'pages-eslogin-qr_login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-help-help', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/help/help.vue')), 'pages-help-help'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-help-help-detail-help-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/help/help-detail/help-detail.vue')), 'pages-help-help-detail-help-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-feedback-feedback', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('C:/Users/xindadi/.jenkins/workspace/h5-itee-agent-prod/src/pages/feedback/feedback.vue')), 'pages-feedback-feedback'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/about/about',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,

          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"关于我们"})
      },
      [
        createElement('pages-about-about', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-about-about',
  isNVue:false,maxWidth:0,
  pagePath:'pages/about/about',
isQuit:true,
isEntry:true,
  windowTop:0
}
},
{
path: '/pages/invite/invite',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"邀请加入"})
      },
      [
        createElement('pages-invite-invite', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-invite-invite',
  isNVue:false,maxWidth:0,
  pagePath:'pages/invite/invite',
  windowTop:0
}
},
{
path: '/pages/success/success',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"邀请加入"})
      },
      [
        createElement('pages-success-success', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-success-success',
  isNVue:false,maxWidth:0,
  pagePath:'pages/success/success',
  windowTop:0
}
},
{
path: '/pages/login/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-login-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/login',
  windowTop:0
}
},
{
path: '/pages/login/qr_login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-login-qr_login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-qr_login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/qr_login',
  windowTop:0
}
},
{
path: '/pages/login/bind',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"微信绑定"})
      },
      [
        createElement('pages-login-bind', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-bind',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/bind',
  windowTop:0
}
},
{
path: '/pages/login/user_bind',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"微信绑定"})
      },
      [
        createElement('pages-login-user_bind', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-user_bind',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/user_bind',
  windowTop:0
}
},
{
path: '/pages/login/login_password',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登陆"})
      },
      [
        createElement('pages-login-login_password', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-login_password',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/login_password',
  windowTop:0
}
},
{
path: '/pages/success/qr_login_success',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-success-qr_login_success', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-success-qr_login_success',
  isNVue:false,maxWidth:0,
  pagePath:'pages/success/qr_login_success',
  windowTop:0
}
},
{
path: '/pages/eslogin/bind',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-eslogin-bind', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-eslogin-bind',
  isNVue:false,maxWidth:0,
  pagePath:'pages/eslogin/bind',
  windowTop:0
}
},
{
path: '/pages/success/bind_success',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"绑定成功"})
      },
      [
        createElement('pages-success-bind_success', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-success-bind_success',
  isNVue:false,maxWidth:0,
  pagePath:'pages/success/bind_success',
  windowTop:0
}
},
{
path: '/pages/success/pm_success',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"绑定成功"})
      },
      [
        createElement('pages-success-pm_success', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-success-pm_success',
  isNVue:false,maxWidth:0,
  pagePath:'pages/success/pm_success',
  windowTop:0
}
},
{
path: '/pages/eslogin/qr_login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-eslogin-qr_login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-eslogin-qr_login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/eslogin/qr_login',
  windowTop:0
}
},
{
path: '/pages/help/help',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"帮助中心"})
      },
      [
        createElement('pages-help-help', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-help-help',
  isNVue:false,maxWidth:0,
  pagePath:'pages/help/help',
  windowTop:0
}
},
{
path: '/pages/help/help-detail/help-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-help-help-detail-help-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-help-help-detail-help-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/help/help-detail/help-detail',
  windowTop:0
}
},
{
path: '/pages/feedback/feedback',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"意见反馈"})
      },
      [
        createElement('pages-feedback-feedback', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-feedback-feedback',
  isNVue:false,maxWidth:0,
  pagePath:'pages/feedback/feedback',
  windowTop:0
}
},
{
path: '/preview-image',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-preview-image', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'preview-image',
  pagePath:'/preview-image'
}
}
    ,
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
