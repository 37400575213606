import { router }  from './router'
import store  from './store'

//全局路由前置守卫
router.beforeEach((to, from, next) => {
  if(to.meta &&　to.meta.needAuth ){
    if(store.getters.accessToken){
        return next()
     }else{
      next({ path: '/pages/login/login', query: { redirect_uri: to.fullPath } })
     }
  }else{
    next()
  }
})
// 全局路由后置守卫
router.afterEach((to, from) => {})
