import 'uni-pages';import 'uni-h5';import Vue from 'vue'
import App from './App'

Vue.config.productionTip = false

App.mpType = 'app'

// 引入全局uView JS
import uView from 'uview-ui';
Vue.use(uView);


// 将各个定义的接口名称，统一放进对象挂载到vm.$api(因为vm就是this，也即this.$api)下
import * as api from '@/api'
Vue.prototype.$api = api

// 路由
import {router,RouterMount} from './router'
Vue.use(router)
import './permission'

import store from './store'
const app = new Vue({
    ...App,
    store
})

//v1.3.5起 H5端 你应该去除原有的app.$mount();使用路由自带的渲染方式

RouterMount(app,router,'#app');






//消息
const success = ({message})=> uni.showToast({title: message,icon: 'success',mask: true})
const fail = ({message})=> uni.showToast({title: message,icon: 'none',mask: true})
Vue.prototype.$handleError=fail
Vue.prototype.$message ={
    success,
    warning:fail,
    error:fail,
}
Vue.prototype.$navTo = function(path,query){
  this.$Router.push({path,query})
}