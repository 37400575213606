import { es_login } from '@/api'
import { setStorage,getStorage } from '@/utils/storage'

const token_key = 'es_token'

export default {
  state: {
    token:getStorage(token_key)||''
  },
  mutations: {
  },
  actions: {
    async es_login({ dispatch }, params) {
      const res = await es_login(params)
      dispatch('es_login_success', res)
    },
    es_login_success({ state }, res) {
      console.log('es_login_success',res,res.token)
      state.token = res.tokenHead + ' ' +res.token
      setStorage(token_key,state.token)
      return res
    },
  }
}
