/**
 * 存储Storage
 */
export const setStorage = (name, value) => {
  return uni.setStorageSync(name,value)
}

/**
 * 获取Storage
 */
export const getStorage = (name) => {
  return uni.getStorageSync(name)
}

/**
 * 删除Storage
 */
export const removeStorage = name => {
  if (!name) return
  return uni.setStorageSync(name,null)
}
