import { ajaxGetDictItems,getSysConfig } from '@/api'

export default {
	state: {
    dict:{},
    sysconfig:{
			article:{},
			cms:{},
			login:{}
		},
	},
	mutations: {
	},
	actions: {
    async getDictItems({state},code){
      state.dict[code] = await ajaxGetDictItems(code)
      return state.dict[code]
    },
    async getSysConfig({state},code){
      state.sysconfig[code] = await getSysConfig(code)
      return state.sysconfig[code]
    }
	}
}
