import axios from './request'

 //上传文件
 export const refreshSTSToken = (params)=> axios.get("/res/oss/sts/token",{params});
//邀请的企业信息
export const invite_enterprise_info = (inviteId)=> axios.get(`/ems/invitemember/enterprise/${inviteId}`)

//join
export const wx_login = ({code})=> axios.post(`/ems/wx/accessToken/${code}`)
export const wx_bind = (params)=> axios.post(`/ems/wx/mp/bind`,params)
export const wx_user_bind = (params)=> axios.post(`/ems/user/mp/bind`,params)

export const user_reg_check = ({inviteId,accessToken})=> axios.post(`/ems/invitemember/check/${inviteId}/${accessToken}`)
export const invite_member_join = (inviteId,params)=> axios.post(`/ems/invitemember/join/${inviteId}`,params)

//扫码登录
export const qr_ticket_user_login = (ticket,params)=> axios.postForm(`/ems/oauth2/scanloginqrcode/${ticket}`,params)

export const bms_qr_ticket_user_login = (ticket,params)=> axios.postForm(`/bms/oauth2/scanloginqrcode/${ticket}`,params)

//内容管理
export const article_list = (params)=> axios.get(`/cms/api/cms/articles`,{params})
export const article_info = (id)=> axios.get(`/cms/api/cms/article/detail`,{params:{id}})
//意见反馈
export const feedback_add = (params)=> axios.post(`/cms/api/cms/saveFeedback`,params)


/**********************************客户端********************************/
export const es_login = (params)=> axios.postForm(`/bms/login`,params)
export const es_wx_bind = (params)=> axios.post(`/bms/mpbind`,params)

//登录
export const login = (params)=> axios.postForm("/ems/login",params);
